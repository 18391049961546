import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useError from 'hooks/useError';
import useGetDataForRedux from 'hooks/useGetDataForRedux';
import { IAppState } from 'store/Store';
import { IFormError } from 'constants/Interfaces';
import { timeConvert } from 'helpers/DateTime';
import { ROUTES } from 'constants/Routes';
import {
  setExperienceWidgetLocation,
  setExperienceWidgetService,
} from 'experience/redux/actions/ExperienceWidgetActions';
import { handleError } from 'middlewares/ErrorHandler';
import Row from 'experience/components/Row';
import Block from 'experience/components/Block';
import Select from 'experience/components/Select';
import ServiceCategory from 'experience/components/Services/ServiceCategory';
import ServiceItem from 'experience/components/Services/ServiceItem';
import {
  IExperienceLocationState,
  ILocationExperience,
} from 'experience/types/ExperienceLocation';
import { getLocationExperience } from 'experience/redux/actions/ExperienceLocationActions';
import {
  IExperiencePricings,
  IExperienceServicesState,
  IServicesExperience,
} from 'experience/types/ExperienceServices';
import { getServicesExperience } from 'experience/redux/actions/ExperienceServicesActions';
import {
  IExperienceServiceGroupsState,
  IServiceGroupsExperience,
} from 'experience/types/ExperienceServiceGroups';
import { getServiceGroupsExperience } from 'experience/redux/actions/ExperienceServiceGroupsActions';
import './ExperienceServices.scss';
import { SKIP_IDS } from 'config/shipConfig';
import {
  setCurrentExperienceWidgeMultiple,
  setExperienceWidgetMultipleInfo,
  setExperienceWidgetMultipleLocation,
  setExperienceWidgetMultipleService,
} from 'experience/redux/actions/ExperienceWidgetActionMultiple';
import EditReservation from 'experience/components/EditReservation';
import { v4 as uuidv4 } from 'uuid';
import { IExperienceWidgetMultipeState } from 'types/ExperienceWidgetMultiple';
import Button, { ButtonSize } from 'experience/components/Button';
import { getSelectedServices } from 'helpers/SessionStorage';
import { removeExperienceWidgetMultiple } from 'experience/redux/actions/ExperienceWidgetActionMultiple';

interface ICompanyService {
  group: IServiceGroupsExperience;
  items: IServicesExperience[];
}

const countServiceItems = (service: ICompanyService) => {
  let val = 0;
  service.items.forEach(i => (val += i.pricings.length));
  return val;
};

const ExperienceServices = () => {
  const { params } = useRouteMatch<{ lang: string; company: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  // states

  const widgetStateMultiple: IExperienceWidgetMultipeState = useSelector<
    IAppState,
    IExperienceWidgetMultipeState
  >((state: IAppState) => state.experienceWidgetMultipeState);

  const locationExperienceState: IExperienceLocationState = useSelector<
    IAppState,
    IExperienceLocationState
  >((state: IAppState) => state.experienceLocationState);

  const servicesExperienceState: IExperienceServicesState = useSelector<
    IAppState,
    IExperienceServicesState
  >((state: IAppState) => state.experienceServicesState);

  const serviceGroupsExperienceState: IExperienceServiceGroupsState = useSelector<
    IAppState,
    IExperienceServiceGroupsState
  >((state: IAppState) => state.experienceServiceGroupsState);

  const [location, setLocation] = useState<ILocationExperience | undefined>();
  const [error, setError] = useState<IFormError>();
  useError(error, setError);

  useGetDataForRedux(
    setError,
    locationExperienceState.data,
    getLocationExperience(params.company),
  );
  useGetDataForRedux(
    setError,
    serviceGroupsExperienceState.data,
    getServiceGroupsExperience(params.company),
  );

  useEffect(() => {
    if (!location) return;
    (async () => {
      try {
        await dispatch(getServicesExperience(params.company, undefined, location.id));
      } catch (err) {
        handleError(
          err?.response?.status,
          setError,
          err.response?.data?.title,
          err.response?.data?.CausedByField,
        );
      }
    })();
  }, [location]);
  useEffect(() => {
    if ((widgetStateMultiple?.selectedServices?.length || 0) > 0) {
      const firstLocation = widgetStateMultiple.selectedServices?.[0].location;
      setLocation(firstLocation);
      return;
    }
    if (locationExperienceState.data) {
      setLocation(locationExperienceState.data[0]);
    }
  }, [locationExperienceState.data]);

  useEffect(() => {
    dispatch(removeExperienceWidgetMultiple());
    try {
      const services = getSelectedServices();
      if (!services) return;
      dispatch(setExperienceWidgetMultipleInfo(services));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const handleServiceItemSelect = (
    item: IServicesExperience,
    pricing: IExperiencePricings,
  ) => {
    if (!location || !item) return;

    dispatch(setExperienceWidgetService(item, pricing));
    dispatch(setExperienceWidgetLocation(location));

    // mutliple reservation store
    const serviceId = uuidv4();
    dispatch(setExperienceWidgetMultipleService(item, pricing, serviceId));
    dispatch(setExperienceWidgetMultipleLocation(location, serviceId));
    dispatch(setCurrentExperienceWidgeMultiple(serviceId, false));

    history.push(
      ROUTES.EXPERIENCE.TIME.replace(':company', params.company).replace(
        ':lang',
        params.lang,
      ),
    );
  };

  const getTime = (service: IExperiencePricings) => {
    if (SKIP_IDS.includes(service.id)) {
      return null;
    }

    return timeConvert(service.duration);
  };

  const handleReservation = () => {
    history.push(
      ROUTES.EXPERIENCE.FORM.replace(':company', params.company).replace(
        ':lang',
        params.lang,
      ),
    );
  };

  const services = useMemo(() => {
    if (!serviceGroupsExperienceState.data) return [];
    const companyServices: Array<ICompanyService> = [];
    serviceGroupsExperienceState.data?.forEach(sg => {
      companyServices.push({
        group: { ...sg },
        items: [
          ...(servicesExperienceState.data?.filter(s => s.groupId === sg.id) || []),
        ],
      });
    });
    return companyServices;
  }, [serviceGroupsExperienceState.data, servicesExperienceState]);

  const isReservationGroupEnabled =
    widgetStateMultiple?.company?.reservationGroupsEnabled;

  const handleLocationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setLocation(locationExperienceState.data?.find(a => a.id === e.target.value));
  };

  return (
    <div className='experience__reservation-form'>
      <Row paddingY={2}>
        <div className='experience__reservertion-options'>
          <Block title={t('reservation_select_salon')}>
            <Select value={location?.id || ''} onChange={handleLocationChange}>
              {locationExperienceState.data
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map(location => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
            </Select>
          </Block>
          <EditReservation />
        </div>
        <Block
          className='experience__services-wrapper'
          title={t('reservation_select_select_service')}
        >
          <div className='experience__services-block'>
            {services
              .sort((a, b) => a.group.name.localeCompare(b.group.name))
              .map(service => (
                <ServiceCategory
                  key={service.group.id}
                  title={service.group.name}
                  count={countServiceItems(service)}
                >
                  {service.items
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(item => (
                      <React.Fragment key={item.id}>
                        {item.pricings.map(pr => (
                          <ServiceItem
                            key={pr.id}
                            maxPersonsCount={item.maxPersonsCount}
                            title={`${item.name}${pr.name ? ' - ' + pr.name : ''}`}
                            quantity={getTime(pr)}
                            price={pr.price}
                            salePrice={pr.salePrice}
                            onSelect={() => handleServiceItemSelect(item, pr)}
                            fixedPersonsCount={item.fixedPersonsCount}
                            isReservationGroupEnabled={isReservationGroupEnabled}
                          />
                        ))}
                      </React.Fragment>
                    ))}
                </ServiceCategory>
              ))}
          </div>
        </Block>
        {widgetStateMultiple.selectedServices?.length ? (
          <div className='reservation__continue'>
            <Button onClick={handleReservation} size={ButtonSize.LG}>
              {t('reservation_select_service')}
            </Button>
          </div>
        ) : null}
      </Row>
    </div>
  );
};

export default ExperienceServices;
