import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Table } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { FieldArray } from 'react-final-form-arrays';
import { useSelector } from 'react-redux';
import FormInput from '../../Form/FormInput';
import { IoClose } from 'react-icons/io5';
import { IReservationSettingsState } from 'types/ReservationSettings';
import { IAppState } from 'store/Store';
import { formatCurrency } from 'helpers/Currency';
import './PriceTable.scss';

interface IPriceTable {
  push?: any;
  submitFailed?: boolean;
  errors?: Record<string, any>;
}

interface IField {
  hours?: string;
  minutes?: string;
  price?: string;
  name?: string;
}

const PriceTable: React.FC<IPriceTable> = ({ push, submitFailed, errors }) => {
  const { t } = useTranslation();
  const required = (value: string) => (value ? undefined : t('required'));
  const [initialValue] = useState<Array<any>>([{}]);
  const reservationSettingsState: IReservationSettingsState = useSelector<
    IAppState,
    IReservationSettingsState
  >((state: IAppState) => state.reservationSettingsState);

  const validateFields = (values: Array<undefined>) => {
    const validationObj = (values as any).map((value: IField) => {
      const fieldErrors: Record<string, any> = {};

      if (!value?.hours && !value?.minutes) {
        fieldErrors.hours = fieldErrors.minutes = t('required');
      }
      if (
        value?.hours &&
        !(parseInt(value.hours, 10) >= 1 && parseInt(value.hours, 10) <= 23)
      ) {
        fieldErrors.hours = t('service_form_duration_hours_error');
      }
      if (
        value?.minutes &&
        !(parseInt(value.minutes, 10) >= 1 && parseInt(value.minutes, 10) <= 59)
      ) {
        fieldErrors.minutes = t('service_form_duration_minutes_error');
      }

      // Price validations
      if (!value?.price) fieldErrors['price'] = t('required');
      // Regex to check if price contains only digits
      if (
        value?.price &&
        (!/^\d+(\.\d{1,2})?$/.test(value.price) || Number(value.price) <= 0)
      ) {
        fieldErrors['price'] = t('INVALID_PRICE');
      }

      if (values.length > 1 && !value?.name) {
        fieldErrors['name'] = t('required');
      }

      return Object.keys(fieldErrors).length === 0 ? undefined : fieldErrors;
    });
    return validationObj;
  };

  return (
    <Table responsive='md' size='sm' striped id='price-table'>
      <tbody>
        <tr>
          <th className='duration-cell'>{`${t('service_table_col_duration')} *`}</th>
          <th>{`${t('service_table_col_price')} *`}</th>
          <th>{`${t('service_table_col_discount')}`}</th>
          <th>{`${t('service_table_col_name')} *`}</th>
          <th>
            <Button
              className='btn-info centerIcon button-add-service '
              onClick={() => push('pricings', {})}
            >
              <FaPlus className='pr-1' />
              {t('form_add_new_form_button')}
            </Button>
          </th>
        </tr>
        <FieldArray name='pricings' validate={validateFields} initialValue={initialValue}>
          {({ fields, meta }) => (
            <>
              {fields.map((name, index) => (
                <tr key={name}>
                  <td>
                    <div className='d-flex duration-wrapper'>
                      <FormInput
                        size='sm'
                        name={`${name}.hours`}
                        type='text'
                        inputClass='mb-0'
                        endButton={t('twig_time_short_hours')}
                        fieldError={{
                          message: meta.error?.[index]?.hours,
                          title: meta.error?.[index]?.hours,
                        }}
                      />
                      <FormInput
                        size='sm'
                        name={`${name}.minutes`}
                        type='text'
                        inputClass='mb-0'
                        endButton={t('report_services_duration_minutes_short')}
                        fieldError={{
                          message: meta.error?.[index]?.hours,
                          title: meta.error?.[index]?.hours,
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <FormInput
                      size='sm'
                      name={`${name}.price`}
                      endButton={formatCurrency(reservationSettingsState?.data?.currency)}
                      type='text'
                      inputClass='mb-0'
                      validation={required}
                    />
                  </td>
                  <td>
                    <FormInput
                      size='sm'
                      name={`${name}.salePrice`}
                      endButton={formatCurrency(reservationSettingsState?.data?.currency)}
                      type='text'
                      inputClass='mb-0'
                    />
                  </td>
                  <td>
                    <FormInput
                      size='sm'
                      name={`${name}.name`}
                      disabled={fields.length === 1}
                      type='text'
                      inputClass={`mb-0`}
                    />
                  </td>
                  <td>
                    <Button
                      size='sm'
                      disabled={fields.length === 1}
                      className='btn btn-sm btn-danger centerIcon mx-1 py-2 my-lg-0'
                      onClick={() => fields.remove(index)}
                    >
                      <IoClose />
                    </Button>
                  </td>
                </tr>
              ))}
              {errors &&
                Object.keys(errors).includes('pricings') &&
                submitFailed &&
                !fields.length && (
                  <tr>
                    <th className='text-danger' colSpan={4}>
                      {/* TODO: new feature need additional translations */}
                      {t('requiredPrice')}
                    </th>
                  </tr>
                )}
            </>
          )}
        </FieldArray>
      </tbody>
    </Table>
  );
};

export default PriceTable;
